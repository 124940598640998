import React, { useCallback, useEffect, useRef } from 'react';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const poster = [
    require('./images/m_rectangle_01.png'),
    require('./images/m_rectangle_02.png'),
    require('./images/m_rectangle_03.png'),
    require('./images/m_rectangle_04.png'),
    require('./images/m_rectangle_05.png'),
    require('./images/m_rectangle_06.png'),
    require('./images/m_rectangle_07.png'),
    require('./images/m_rectangle_08.png'),
    require('./images/m_rectangle_09.png'),
    require('./images/m_rectangle_10.png'),
    require('./images/m_rectangle_11.png'),
    require('./images/m_rectangle_12.png'),
    require('./images/m_rectangle_13.png'),
    require('./images/m_rectangle_14.png'),
    require('./images/m_rectangle_15.png'),
    require('./images/m_rectangle_16.png'),
    require('./images/m_rectangle_17.png'),
    require('./images/m_rectangle_18.png'),
    require('./images/m_rectangle_19.png'),
    require('./images/m_rectangle_20.png'),
];

const screenshot = [require('./images/group-13-jp.png'), require('./images/group-4-jp.png')];

export function string_is_empty(string_: string | null | undefined): string_ is null | undefined {
    return string_ == null || string_.length === 0;
}

function App() {
    const render_post = useCallback(() => poster.map((val, idx) => <img key={idx} className={idx % 2 === 0 ? 'under' : ''} src={val} alt="" />), []);
    const render_screenshot = useCallback(() => screenshot.map((val, idx) => <img key={idx} src={val} alt="" />), []);

    const url = new URL(window.location.href);
    const url_params = url.searchParams;
    const full_link_url = url_params.get('full_link_url');
    useEffect(() => {
        if (!string_is_empty(full_link_url)) {
            const now = Date.now();
            setTimeout(() => {
                if (Date.now() - now > 100) {
                    return;
                }

                window.location.replace('/');
            }, 50);

            window.location.href = full_link_url;
        }
    }, [full_link_url]);

    window.location.href = 'https://smart.mnetjp.com';

    return (
        <div className="wrap landing-jp">
            <div className="fix-header" style={{ display: 'none' }}>
                <div className="left-box">
                    <img src="./assets/appicon-m-jp.png" alt="" />
                    <p>アプリならよりスムーズにコンテンツをお楽しみいただけます</p>
                    <p style={{ display: 'none' }}>MCOUNTTDOWN 투표에 참여해 주세요</p>
                    <p style={{ display: 'none' }}>당신이 좋아하는 아티스트에 대해 알려 주세요</p>
                    <p style={{ display: 'none' }}>최애가 보내는 Private mail을 받아 보세요</p>
                    <p style={{ display: 'none' }}>
                        Mnet Smart +<span>Mnet Smart + 앱에서 열기</span>
                    </p>
                </div>
                <div className="right-box">
                    <a href="https://onelink.to/3fxszv">ダウンロード</a>
                    <a href="https://onelink.to/3fxszv" target="_blank" className="blue-btn" style={{ display: 'none' }}>
                        열기
                    </a>
                </div>
            </div>
            <div className="header">
                <h1>
                    <a href="https://onelink.to/3fxszv"></a>
                </h1>
                <div className="nav-header">
                    <a href="https://onelink.to/3fxszv"></a>
                </div>
            </div>
            <div className="landing-wrap">
                <div className="landing-box" id="ani01">
                    <div className="tit-box">
                        <h2>
                            韓国エンタメ動画配信サービスと
                            <br />
                            ファンコミュニティが融合した
                            <br />
                            <br />
                            日本最大級のK-POP <br />
                            <span>ファンコミュニティプラットフォーム</span>
                        </h2>
                    </div>
                    <div className="loop-img-box default">
                        <Slider
                            {...{
                                speed: 3000,
                                autoplay: true,
                                autoplaySpeed: 0,
                                cssEase: 'linear',
                                slidesToShow: 1,
                                pauseOnHover: false,
                                pauseOnFocus: false,
                                variableWidth: true,
                                arrows: false,
                                infinite: true,
                                swipe: false,
                                accessibility: false,
                                draggable: false,
                                touchMove: false,
                                useTransform: false,
                            }}
                        >
                            {render_post()}
                        </Slider>
                    </div>
                    <div className="btn-box">
                        <a href="https://onelink.to/3fxszv">まずはアプリダウンロード</a>
                    </div>
                </div>

                <div className="landing-box" id="ani02">
                    <div className="tit-box">
                        <h2>
                            韓国で放送中の「<span>Mnet</span>」が
                            <span>リアルタイム</span>で視聴可能！
                        </h2>
                        <p>
                            韓国ケーブルテレビで放送中の
                            <br />
                            Mnet Koreaや
                            <br />
                            日本CSチャンネルMnet Japanが
                            <br />
                            リアルタイムで携帯やPCから視聴可能！
                            <br />
                            <br />
                            さらに、今韓国で話題の
                            <br />
                            ドラマ・バラエティ・音楽作品が
                            <br />
                            字幕付きで見放題 !
                        </p>
                    </div>
                    <div className="on-air-img">
                        <img src={require('./images/m_group-8-jp.png')} alt="" />
                    </div>
                </div>

                <div className="landing-box" id="ani03">
                    <div className="tit-box">
                        <h2>
                            ファン同士で盛り上がれる
                            <br />
                            <span>コミュニティ！</span>
                        </h2>
                        <p>
                            推しが同じファン同士で
                            <br />
                            コミュニケーションしながら
                            <br />
                            最新情報をいつでもキャッチしよう！
                        </p>
                    </div>
                    <div className="loop-img-box gradient">
                        <Slider
                            {...{
                                speed: 3500,
                                autoplay: true,
                                autoplaySpeed: 0,
                                cssEase: 'linear',
                                useCss: true,
                                slidesToShow: 1,
                                pauseOnHover: false,
                                pauseOnFocus: false,
                                variableWidth: true,
                                arrows: false,
                                infinite: true,
                                swipe: false,
                            }}
                        >
                            {render_screenshot()}
                        </Slider>
                    </div>
                </div>

                <div className="landing-box" id="ani04">
                    <div className="tit-box">
                        <img src={require('./images/appicon-m-jp.png')} alt="" />
                        <h3>
                            今すぐアプリをダウンロードして
                            <br />
                            <span>Mnet Smart+</span>を楽しもう！
                        </h3>
                    </div>
                    <div className="download-btn">
                        <a href="https://apps.apple.com/jp/app/mnet-smart/id944171506" className="app-store-btn">
                            <img src={require('./images/m_group-32.png')} alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.mnet.smartservice" className="google-play-btn">
                            <img src={require('./images/google-m_play-badge-1.png')} alt="" />
                        </a>
                    </div>

                    <div className="sns-box">
                        <ol>
                            <li className="twitter-ico">
                                <a href="https://twitter.com/mnet_smart/" target="_blank"></a>
                            </li>
                            <li className="instagram-ico">
                                <a href="https://www.instagram.com/mnetsmart/" target="_blank"></a>
                            </li>
                            <li className="youtube-ico">
                                <a href="https://www.youtube.com/user/MNETJAPAN" target="_blank"></a>
                            </li>
                        </ol>
                    </div>

                    <div className="btn-box white">
                        <a href="https://onelink.to/3fxszv">まずはアプリダウンロード</a>
                    </div>

                    <div className="footer-box" style={{ display: 'none' }}>
                        <ol>
                            <li>
                                <a href="https://smart.mnetjp.com/serviceinfo/terms" target="_blank">
                                    利用規約
                                </a>
                            </li>
                            <li>
                                <a href="https://smart.mnetjp.com/serviceinfo/privacy" target="_blank">
                                    プライバシーポリシー{' '}
                                </a>
                            </li>
                            <li>
                                <a href="https://smart.mnetjp.com/serviceinfo/deal_law" target="_blank">
                                    特商法取引について
                                </a>
                            </li>
                            <li>
                                <a href="https://smart.mnetjp.com/support/faq" target="_blank">
                                    よくある質問
                                </a>
                            </li>
                            <li>
                                <a href="https://smart.mnetjp.com/support/request" target="_blank">
                                    問い合わせ{' '}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
